import React from 'react';
import { styled } from '@glitz/react';
import { epiPropertyValue, EpiProperty } from '@avensia/scope-episerver';
import Add from 'Shared/Icon/Add';
import Accordion from 'Shared/Accordion';
import { pixelsToUnit, transition, concrete } from 'Shared/Style';
import FaqBlockType from 'CustomerService/FaqBlock.type';

type PropType = { items: Scope.ContentAreaItem[] };

type ItemType = {
  index: number;
  isOpen: boolean;
};

type StateType = {
  accordionItems: ItemType[];
};

class ListBlockAccordion extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      accordionItems: [],
    };
  }

  onToggle(index: number) {
    const { accordionItems } = this.state;
    const newaccordionItems = accordionItems.map((item: ItemType, idx: number) =>
      idx === index ? { ...item, isOpen: !item.isOpen } : { ...item, isOpen: false },
    );
    this.setState({ accordionItems: newaccordionItems });
  }

  renderAccordionHeader = (heading: string, index: number) => {
    return (
      <AccordionHeader>
        <HeaderText css={{ fontWeight: this.state.accordionItems[index].isOpen ? 'bold' : 'normal' }}>
          {heading}
        </HeaderText>
        <ViewMore
          css={{
            transform: this.state.accordionItems[index].isOpen ? 'rotate(135deg)' : 'rotate(0)',
          }}
        />
      </AccordionHeader>
    );
  };

  UNSAFE_componentWillMount() {
    if (this.props.items.length > 0) {
      for (let i = 0; i <= this.props.items.length; i++) {
        const accordionItems = this.state.accordionItems;
        accordionItems.push({ index: i, isOpen: false });
        this.setState({ accordionItems });
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.items.map((item: Scope.ContentAreaItem & FaqBlockType, index: number) => (
          <CustomAccordion
            key={epiPropertyValue(item.header) + index}
            header={this.renderAccordionHeader(epiPropertyValue(item.header), index)}
            body={<EpiProperty for={item.text} />}
            isOpen={this.state.accordionItems[index].isOpen}
            // tslint:disable-next-line:jsx-no-lambda
            onToggle={() => this.onToggle(index)}
          />
        ))}
      </div>
    );
  }
}

const AccordionHeader = styled.div({
  alignItems: 'center',
  display: 'flex',
  minHeight: pixelsToUnit(44),
  justifyContent: 'space-between',
});
const HeaderText = styled.h5({
  display: 'flex',
  flexBasis: '88%',
  textAlign: 'left',
  letterSpacing: pixelsToUnit(0.36),
  padding: {
    y: pixelsToUnit(10),
  },
});

const CustomAccordion = styled(Accordion, {
  backgroundColor: concrete,
  marginBottom: pixelsToUnit(10),
  paddingLeft: pixelsToUnit(10),
});

const ViewMore = styled(Add, {
  display: 'flex',
  flexBasis: '12%',
  ...transition({
    property: 'all',
    duration: '.2s',
    willChange: true,
    timingFunction: 'ease',
  }),
});

export default ListBlockAccordion;
