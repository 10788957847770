import React from 'react';
import { translate } from '@avensia/scope';
import Link from 'Shared/Link';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import BreadcrumbsViewModel from './BreadcrumbsViewModel.type';
import Ul from '../Generic/Ul';
import { scorpion, minMediumMediaQuery, pixelsToUnit } from 'Shared/Style';
import connect from 'Shared/connect';
import isVariationPage from 'Product/current-page-is-variation';
import { ItemKeyType } from 'SiteLayout/MainMenu/item';
import { navigateMainMenu } from 'SiteLayout/MainMenu/action-creators';

type RequiredPropType = {
  breadcrumbs: BreadcrumbsViewModel[];
  isSliced?: boolean;
};

type ConnectStateType = {
  isVariationPage: boolean;
};

type ConnectDispatchType = {
  navigateMainMenu: (key: ItemKeyType) => void;
};

type PropType = RequiredPropType & ConnectStateType & StyledProps & ConnectDispatchType;

const Li = styled.li({
  marginLeft: pixelsToUnit(5),
  ...pseudo(':not(:last-child):after', {
    content: '"/"',
    marginLeft: pixelsToUnit(5),
  }),
});

const PathLink = styled(Link, {
  color: scorpion,
  ':hover': {
    textDecoration: 'underline',
  },
  ':focus': {
    textDecoration: 'none',
  },
});

const CurrentLink = styled(PathLink, {
  fontWeight: 'bold',
});

const placeholderBreadcrum = [
  {
    text: '',
    url: '/',
  },
] as BreadcrumbsViewModel[];

export default styled(
  connect(
    (state): ConnectStateType => ({
      isVariationPage: isVariationPage(state.currentPage),
    }),

    (dispatch): ConnectDispatchType => ({
      navigateMainMenu(key: ItemKeyType) {
        dispatch(navigateMainMenu(key));
      },
    }),
  )((props: PropType) => (
    <styled.Div css={props.compose()}>
      <Ul
        css={{
          color: scorpion,
          display: 'inline-flex',
          flexFlow: 'row wrap',
          justifyContent: 'center',
          lineHeight: 'normal',
          [minMediumMediaQuery]: {
            justifyContent: 'flex-start',
          },
        }}
        row
      >
        {(props.breadcrumbs || placeholderBreadcrum)
          .slice(props.isSliced ? -1 : 0)
          .map((breadcrumb, index, { length }) => {
            const Item = props.isVariationPage && index === length - 1 && !props.isSliced ? CurrentLink : PathLink;

            return (
              <Li key={breadcrumb.url + index} itemProp="itemListElement">
                <Item to={breadcrumb.url} itemProp="item" onClick={(e) => props.navigateMainMenu(breadcrumb.url)}>
                  {index === 0 && !props.isSliced
                    ? translate('/Shared/Breadcrumbs/Start')
                    : breadcrumb.text.charAt(0).toUpperCase() + breadcrumb.text.substr(1).toLowerCase()}
                </Item>
                <meta itemProp="position" content={String(index + 1)} />
              </Li>
            );
          })}
      </Ul>
    </styled.Div>
  )),
);
