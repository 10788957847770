/**
 * @ComponentFor CustomerServiceMainPageViewModel
 */

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { epiPropertyValue, EpiProperty } from '@avensia/scope-episerver';
import { Main, Basic, Appearance, Layout } from 'Shared/PageLayout/index';
import CustomerServiceMainPageViewModelType from './CustomerServiceMainPageViewModel.type';
import { pixelsToUnit, minMediumMediaQuery } from 'Shared/Style';
import { iota, alpha } from 'Shared/Style/typography';
import BreadCrumbs from 'Shared/Breadcrumbs';
import LinkBlockContentArea from './components/LinkBlockContentArea';
import Viewport from 'Shared/Viewport';
import ListFaqBlockAccordion from './components/ListFaqBlockAccordion';
import ContactUs from 'CustomerService/components/ContactUs';

type BreakpointType = {
  currentBreakpoint: number;
};
type PropType = CustomerServiceMainPageViewModelType & BreakpointType & StyledProps;

const CustomerServiceMainPage = (props: PropType) => (
  <MainContent appearance={Appearance.Narrow}>
    <PaddedContentWrapper>
      <BreadCrumbsWrapper>
        <BreadCrumbs breadcrumbs={props.breadCrumbs} />
      </BreadCrumbsWrapper>
      <Heading>{epiPropertyValue(props.page.pageHeader)}</Heading>
    </PaddedContentWrapper>
    <Basic>
      <LinkBlockContentArea items={props.page.linkBlockHolderBlockArea} />
      <PaddedContentWrapper>
        <FaqHeader>{epiPropertyValue(props.page.faqHeader)}</FaqHeader>
        <Viewport>
          {(isCompact: boolean) => {
            const parseLeftBlock = epiPropertyValue(props.page.faqBlockAreaLeft);
            const parseRightBlock = epiPropertyValue(props.page.faqBlockAreaRight);
            const faqLeftItems = !!parseLeftBlock ? parseLeftBlock.items : [];
            const faqRightItems = !!parseRightBlock ? parseRightBlock.items : [];
            const faqItems = faqLeftItems.concat(faqRightItems);

            return isCompact ? (
              <ListFaqBlockAccordion items={faqItems} />
            ) : (
                <Basic layout={Layout.OneToOne}>
                  <EpiProperty for={props.page.faqBlockAreaLeft} />
                  <EpiProperty for={props.page.faqBlockAreaRight} />
                </Basic>
              );
          }}
        </Viewport>
        <ContactUs
          header={epiPropertyValue(props.page.contactUsHeader)}
          text={epiPropertyValue(props.page.contactUsText)}
          email={epiPropertyValue(props.page.contactUsEmail)}
        />
      </PaddedContentWrapper>
    </Basic>
  </MainContent>
);

export default styled(CustomerServiceMainPage);

const MainContent = styled(Main, {
  paddingBottom: pixelsToUnit(100),
  maxWidth: pixelsToUnit(1400),
});

const BreadCrumbsWrapper = styled.div({
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

const Heading = styled.h2({
  fontWeight: 'normal',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    fontSize: alpha,
    textAlign: 'left',
  },
});

const FaqHeader = styled.div({
  fontSize: iota,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(2.44),
  padding: { y: pixelsToUnit(30) },
});

const PaddedContentWrapper = styled.div({
  padding: { x: pixelsToUnit(5) },
  [minMediumMediaQuery]: {
    padding: { x: pixelsToUnit(10) },
  },
});
